<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half d-table w-100" style="background: url('/images/about-top.jpeg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <h1 class="text-white title-dark">網站託管/維運</h1>
              <p class="text-white-50 para-desc mb-0 mx-auto">將網站託付給嗨嗨，我們有專業的虛擬伺服器跟監控維運技術</p>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">首頁</router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">服務項目</li>
                    <li class="breadcrumb-item active" aria-current="page">網站託管/維運</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 col-md-8">
            <div class="row mt-4 pt-2">
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"/><path d="M9 9h6v6H9z"/><path d="M9 1v3"/><path d="M15 1v3"/><path d="M9 20v3"/><path d="M15 20v3"/><path d="M20 9h3"/><path d="M20 14h3"/><path d="M1 9h3"/><path d="M1 14h3"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">雲伺服器</h4>
                    <p
                      class="text-muted para mb-0"
                    >我們熟悉各式雲虛擬伺服器的基礎環境建置和維護，我們使用的平台也很多元，像是：GCP、AWS、阿里雲...等等</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 22s8-4 8-10V5l-8-3l-8 3v7c0 6 8 10 8 10z"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">安全性</h4>
                    <p
                      class="text-muted para mb-0"
                    >對於資安的要求，也是嗨嗨數位追求的重點，配合漏洞掃描和弱點分析，我們協助企業打造安全的環境來放置您的產品。</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><path d="M22 4L12 14.01l-3-3"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">24小時監控</h4>
                    <p
                      class="text-muted para mb-0"
                    >嗨嗨數位建置自己的監控系統，隨時掌握每台伺服器的情況，並在異常出現時透過警示，儘早解決問題，降低營運衝擊。</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"/><rect x="2" y="14" width="20" height="8" rx="2" ry="2"/><path d="M6 6h.01"/><path d="M6 18h.01"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">備份</h4>
                    <p
                      class="text-muted para mb-0"
                    >提供網站內容跟資料庫備份的服務，並使用異地備份的方式確保每個備份都存在於兩個不同的機房。</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"/><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83a2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33a1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0a2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82a1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2a2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83a2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51a1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0a2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">持續整合與發佈</h4>
                    <p
                      class="text-muted para mb-0"
                    >透過持續整合與發佈，避免網站在人工部署時容易發生人為失誤的情況，實現自動化測試跟部署流程</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="media features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary mr-3 mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24px" height="24px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"/><rect x="2" y="14" width="20" height="8" rx="2" ry="2"/><path d="M6 6h.01"/><path d="M6 18h.01"/></g></svg>
                  </div>
                  <div class="media-body">
                    <h4 class="title">高可用性架構</h4>
                    <p
                      class="text-muted para mb-0"
                    >打造多點式叢集架構，讓您的網站隨時依據流量和負載情況，動態調整伺服器數量，乘載高流量情境。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right">
            <img src="/images/hosting/1.png" alt class="img-fluid" />
          </div>
        </div>
        <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">您有其他需求嗎？</h4>
              <p class="text-muted para-desc mx-auto">
                都歡迎跟嗨嗨 Say Hi，讓我們提供專屬於您的解決方案
              </p>
              <div class="mt-4">
                <router-link :to="{name: 'Contact'}" class="btn btn-primary mt-2 mr-2">
                  聯絡我們
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
<script>
import { ArrowUpIcon, ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Page-aboutus-two component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon
  }
};
</script>